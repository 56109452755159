var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
//import logo from './assets/images/logo_cabecalho.png' // PRODUÇÃO
import logo from './assets/images/logo_cabecalho_teste.png'; // TODO: TESTE
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Detail from './pages/Detail';
import Create from './pages/Create';
import Dashboard from './pages/Dashboard';
import { BiBuildingHouse } from 'react-icons/bi';
import { VscGraph, VscKey } from 'react-icons/vsc';
import { AiOutlineBell, AiOutlineFileSearch, AiOutlineRead } from 'react-icons/ai';
import { RiComputerLine } from 'react-icons/ri';
import { SlEnvolopeLetter } from "react-icons/sl";
import Search from './pages/Search';
import Company from './pages/Company';
import Licenses from './pages/Licenses';
import Libraries from './pages/Libraries';
import EnvelopeManagement from './pages/EnvelopeManagement';
import useAuth from './hooks/useAuth';
import Register from './pages/Register';
import ChangePassword from './pages/ChangePassword';
import RequestUserLogin from './pages/RequestUserLogin';
import useTitle from './hooks/useTitle';
import { SideBar } from 'template/lib';
var Routes = function () {
    var _a = useAuth(), login = _a.login, handleLogout = _a.handleLogout;
    var menu = [
        { name: 'Início', link: '/profile', icon: _jsx(RiComputerLine, { className: 'text-xl' }, void 0) },
        { name: 'Novo Chamado', link: '/create', icon: _jsx(AiOutlineBell, { className: 'text-xl' }, void 0) }
    ];
    if ((login === null || login === void 0 ? void 0 : login.role) === 'Administrator' || (login === null || login === void 0 ? void 0 : login.role) === 'Help') {
        menu.push({ name: 'Dashboard', link: '/dashboard', icon: _jsx(VscGraph, { className: 'text-xl' }, void 0) }, { name: 'Pesquisa', link: '/search', icon: _jsx(AiOutlineFileSearch, { className: 'text-xl' }, void 0) }, { name: 'Empresas', link: '/company', icon: _jsx(BiBuildingHouse, { className: 'text-xl' }, void 0) });
    }
    else if ((login === null || login === void 0 ? void 0 : login.role) === 'CustomerAdmin') {
        menu.push({ name: 'Licenças', link: '/licenses', icon: _jsx(VscKey, { className: 'text-xl' }, void 0) });
    }
    // Após estabilizar a atualização da biblioteca no 3D, remover do if abaixo a condição: && login?.role !== 'CustomerAdmin'
    if ((login === null || login === void 0 ? void 0 : login.role) !== 'Customer' && (login === null || login === void 0 ? void 0 : login.role) !== 'CustomerAdmin' && (login === null || login === void 0 ? void 0 : login.hasPluginMaker) == true) {
        menu.push({ name: 'Bibliotecas', link: '/libraries', icon: _jsx(AiOutlineRead, { className: 'text-xl' }, void 0) });
    }
    if ((login === null || login === void 0 ? void 0 : login.role) === 'Administrator') {
        menu.push({ name: 'Gerenciamento de Envelopes', link: '/envelope-management', icon: _jsx(SlEnvolopeLetter, { className: 'text-xl' }, void 0) });
    }
    var Header = {
        logOut: function () { return handleLogout(); },
        user: {
            name: login === null || login === void 0 ? void 0 : login.name,
            company: login === null || login === void 0 ? void 0 : login.company
        },
        logoUrl: logo
    };
    var title = useTitle().title;
    var logOut = function () {
        handleLogout();
    };
    return (_jsx(BrowserRouter, { children: _jsxs(Switch, { children: [_jsx(Route, { path: '/', exact: true, component: Login }, void 0), _jsx(Route, { path: '/register', exact: true, component: Register }, void 0), _jsx(Route, { path: '/register/:id/:code', component: ChangePassword }, void 0), _jsx(Route, { path: '/requestuser/', exact: true, component: RequestUserLogin }, void 0), login ?
                    _jsx(Route, { children: _jsx("div", __assign({ className: 'pt-20 ' }, { children: _jsx(SideBar, __assign({ title: title || '', Header: Header, SideBar: { menu: menu } }, { children: _jsxs(_Fragment, { children: [_jsx(Route, { path: "/profile", exact: true, component: Profile }, void 0), _jsx(Route, { path: "/profile/:id", component: Detail }, void 0), _jsx(Route, { path: "/profileNewPage/:id", component: Detail }, void 0), _jsx(Route, { path: "/create", component: Create }, void 0), (login.role === 'Help' || login.role === 'Administrator') && _jsx(Route, { path: "/dashboard", component: Dashboard }, void 0), (login.role === 'Help' || login.role === 'Administrator') && _jsx(Route, { path: "/search", component: Search }, void 0), (login.role === 'Help' || login.role === 'Administrator') && _jsx(Route, { path: "/company", component: Company }, void 0), login.role === 'CustomerAdmin' && _jsx(Route, { path: "/licenses", component: Licenses }, void 0), login.role !== 'Customer' && login.role !== 'CustomerAdmin' && (login === null || login === void 0 ? void 0 : login.hasPluginMaker) == true && _jsx(Route, { path: "/libraries", component: Libraries }, void 0), login.role === 'Administrator' && _jsx(Route, { path: "/envelope-management", component: EnvelopeManagement }, void 0)] }, void 0) }), void 0) }), void 0) }, void 0)
                    :
                        _jsxs(_Fragment, { children: [_jsx(Route, { path: '*', exact: true, component: Login }, void 0), !window.location.href.includes('profile/') &&
                                    _jsx(Redirect, { from: '*', to: '/' }, void 0)] }, void 0)] }, void 0) }, void 0));
};
export default Routes;
